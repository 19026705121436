import React, {Fragment, useEffect, useState, useContext} from 'react';
import {Form, Input, Radio, Checkbox, Button} from 'antd';
import AddressField from './AddressField';
import styled from 'styled-components';
import {EnvironmentOutlined} from '@ant-design/icons';
import {Context} from '../../AppContext';
import useDimension from '../../hooks/use-dimension';
import {DELIVERY_TYPE, STORE_CHANNEL} from '../../dictionary';
const appConfig = require('../../data.json');

export default function ShippingInfo({config: _config, setConfig}) {
  const config = _config.deliveryConfig;
  const userConfig = _config.userConfig;
  const app = useContext(Context);
  const {dimension} = useDimension();
  const form = Form.useFormInstance();
  const [sameAsUser, setSameAsUser] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      sender_name: config.sender_name,
      sender_phone: config.sender_phone,
      sender_zip: config.sender_zip,
      sender_address: config.sender_address,
      sender_city: config.sender_city,
      sender_district: config.sender_district,
      receiver_name: config.receiver_name,
      receiver_phone: config.receiver_phone,
      receiver_address: config.receiver_address,
      receiver_city: config.receiver_city,
      receiver_district: config.receiver_district,
      zip_code: config.zip_code,
      note: config.note,
      rstore_addr: config.rstore_addr,
      rstore_name: config.rstore_name,
      st_state: config.st_state,
    });
  }, [form, config]);

  useEffect(() => {
    if (sameAsUser) {
      form.setFieldsValue({
        ...config,
        receiver_name: userConfig.name,
        receiver_phone: userConfig.phone,
        receiver_address: userConfig.address,
        receiver_city: userConfig.city,
        receiver_district: userConfig.district,
        zip: userConfig.zip_code,
        zip_code: userConfig.zip_code,
      });
    }
  }, [sameAsUser]);

  const convenienceStorePickUp = () => {
    if (config.st_state) {
      return `${STORE_CHANNEL[config.st_state]} 超商取貨`;
    }
    return DELIVERY_TYPE[config.delivery_type];
  };

  return (
    <Fragment>
      {config.is_delivery_private && (
        <>
          <StyledFormItem label="寄件人姓名" name="sender_name">
            <Input placeholder="寄件人姓名" />
          </StyledFormItem>
          <StyledFormItem label="寄件人手機號碼" name="sender_phone">
            <Input placeholder="0900-111-222" />
          </StyledFormItem>
          <StyledFormItem label="寄件人地址">
            <AddressField
              zip_code={config.sender_zip}
              city={config.sender_city}
              prefix="sender_"
            />
          </StyledFormItem>
        </>
      )}
      {['payuni', 'xdelivery'].includes(config.delivery_type) && (
        <p style={{color: `${appConfig.colors.main}`, marginBottom: 16}}>
          收件方式：{convenienceStorePickUp()}
        </p>
      )}
      <StyledFormItem name="same_as_user" valuePropName="checked">
        <Checkbox
          onChange={(e) => {
            setSameAsUser(e.target.checked);
          }}>
          同訂購人資料
        </Checkbox>
      </StyledFormItem>
      <StyledFormItem
        label="收件人姓名"
        name="receiver_name"
        extra="請填寫「與證件相符之真實姓名」並於超商出示身分證件取件。">
        <Input placeholder="請填寫與證件相符之真實姓名" />
      </StyledFormItem>
      <StyledFormItem label="手機號碼" name="receiver_phone">
        <Input placeholder="0900-111-222" />
      </StyledFormItem>
      {['payuni', 'xdelivery'].includes(config.delivery_type) ? (
        <>
          <StyledFormItem label="取件門市" name="rstore_name">
            {config.rstore_name !== '' && (
              <StyledStoreInfo>
                <EnvironmentOutlined />
                <div>{config.rstore_name}</div>
                <div>（{config.rstore_addr}）</div>
              </StyledStoreInfo>
            )}
            <StyledButton
              onClick={() => {
                const orginalWebsiteUrl = new URL(`${appConfig.siteUrl}/cart`);
                orginalWebsiteUrl.searchParams.append('step', '2');
                orginalWebsiteUrl.searchParams.append('modal', 'shipping');
                orginalWebsiteUrl.searchParams.append(
                  'receiver_name',
                  form.getFieldValue('receiver_name'),
                );
                orginalWebsiteUrl.searchParams.append(
                  'receiver_phone',
                  form.getFieldValue('receiver_phone'),
                );
                const url =
                  config.delivery_type === 'payuni'
                    ? app.actions.getPayuniShipMap(
                        dimension.innerWidth < appConfig.breakpoints.lg,
                        encodeURIComponent(orginalWebsiteUrl),
                      )
                    : app.actions.getXdeliveryShipMap(
                        encodeURIComponent(orginalWebsiteUrl),
                      );
                window.open(url, '_self');
              }}>
              {config.rstore_name !== '' ? '編輯門市' : '新增門市'}
            </StyledButton>
          </StyledFormItem>
        </>
      ) : (
        <>
          <StyledFormItem label="收件地址">
            <AddressField
              zip_code={config.zip_code}
              city={config.sender_city}
              prefix="receiver_"
            />
          </StyledFormItem>
          <StyledFormItem label="收件備註" name="delivery_note">
            <Input placeholder="收件備註" />
          </StyledFormItem>
          <StyledFormItem name="info" valuePropName="checked">
            <Radio>儲存成為常用收件資訊</Radio>
          </StyledFormItem>
        </>
      )}

      {/* <Button onClick={handleOnSubmit}>送出</Button> */}
    </Fragment>
  );
}

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    color: #505050;
  }
  input {
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 10px 12px;
    font-size: 14px;
    color: #505050;
  }
  .ant-radio-wrapper {
    font-size: 14px;
    color: #505050;
  }

  .ant-form-item {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .ant-form-item-extra {
    font-size: 14px;
    color: #6c6c6c;
    margin-top: 8px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const StyledStoreInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
  height: 28px;
  color: ${appConfig.colors.textSecond};
  font-weight: 500;
`;

const StyledButton = styled(Button)`
  width: 80px;
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  color: ${appConfig.footer.copyrightColor};
  border: 1px solid transparent;
  background-color: ${appConfig.footer.labelColor};

  &:hover {
    color: ${appConfig.footer.labelColor};
    border: 1px solid #40444c;
  }
`;
